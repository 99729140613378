import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
//import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"

const generalData = {
    title: "Next.jsでつくるフルスタックアプリ（前編・後編）",
    updated: "2024/3/18",
    urlToApp: "https://nextbook-fullstack.vercel.app",
    urlToCode1: "https://github.com/mod728/nextjs-book-full-stack-app/tree/chapter5",
    urlToCode2: "https://github.com/mod728/nextjs-book-full-stack-app",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B09YWSHR58",
    reviewLink2: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B09YXSN96J"

}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるNext.jsサイト", img: NextBook, url: "https://www.amazon.co.jp/dp/B0C7746ZC4", desciption: "2時間でNext.jsの使い方がわかる入門書。<br/><br/>最新のNext.jsバージョン13と、新たに導入されたAppフォルダの使い方を解説している唯一の日本語書籍（2023年6月時点）"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "Next.jsでつくるフルスタックアプリ with TypeScript　前編・後編", img: NextBook2aTS, url: "https://www.amazon.co.jp/dp/B0BD552RD6", desciption: "実際の開発現場ではNext.jsをTypeScriptとセットで使うことがスタンダードになっています。<br/><br/>本書にTypeScriptへの書き換えを解説する補章を追加した増補版。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "MERNでつくるフルスタックアプリ（前編・後編）", img: MernBook , url: "https://www.amazon.co.jp/dp/B0BJN4H1NF", desciption: "Node.js、Express、React、MongoDBを使ってバックエンド、フロントエンド開発を行ない、アプリを作ります。<br/><br/>フルスタックアプリ開発の入門に最適な一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるReactアプリ with TypeScript", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", desciption: "「React開発のスタンダードReact + TypeScriptを学んでみたい」<br/><br/>という人向けのReact + TypeScript入門書です。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", desciption: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", desciption: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", desciption: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", desciption: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", desciption: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", desciption: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", desciption: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", desciption: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", desciption: ""},
    {newRelease: "", title: "", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", desciption: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", desciption: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", desciption: ""},
]

const links = [
    {
        chapterNum: "はじめに",
        data: [
            {name: "JSON Formatter", url: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa/related"}
        ]
    },
    {
        chapterNum: 1,
        data: [
            {name: "Node.js", url: "https://nodejs.org/en/download"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "パッケージ情報", url: "https://github.com/mod728/nextjs-book-full-stack-app/blob/master/package.json"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "リクエストの結果番号", url: "https://developer.mozilla.org/ja/docs/Web/HTTP/Status"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "MongoDB", url: "https://www.mongodb.com"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "JSON Web Token", url: "https://jwt.io"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "Vercel", url: "https://vercel.com"},
            {name: "第1-5章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-full-stack-app/tree/chapter5"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink1},
        ]
    },
    {
        chapterNum: 6,
        data: [
            {name: "1つ目のアイテム", url: "https://github.com/mod728/nextjs-book-full-stack-app/blob/master/item-data/1st-item.md"},
            {name: "2つ目のアイテム", url: "https://github.com/mod728/nextjs-book-full-stack-app/blob/master/item-data/2nd-item.md"},
            {name: "3つ目のアイテム", url: "https://github.com/mod728/nextjs-book-full-stack-app/blob/master/item-data/3rd-item.md"},
            {name: "4つ目のアイテム", url: "https://github.com/mod728/nextjs-book-full-stack-app/blob/master/item-data/4th-item.md"},
            {name: "5つ目のアイテム", url: "https://github.com/mod728/nextjs-book-full-stack-app/blob/master/item-data/5th-item.md"},
            {name: "6つ目のアイテム", url: "https://github.com/mod728/nextjs-book-full-stack-app/blob/master/item-data/6th-item.md"},
            {name: "画像", url: "https://github.com/mod728/nextjs-book-full-stack-app/tree/master/public"},
        ]
    },
    {
        chapterNum: 8,
        data: [
            {name: "CSS", url: "https://github.com/mod728/nextjs-book-full-stack-app/blob/master/styles/globals.css"},
        ]
    },
    {
        chapterNum: 9,
        data: [
            {name: "第6-9章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-full-stack-app"},
        ]
    },
    {
        chapterNum: 10,
        data: [
            {name: "Base64変換サイト", url: "https://www.base64-image.de"},
            {name: "Cloudinary", url: "https://cloudinary.com"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink2},
        ]
    },
]

const NextFullstackBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            <li><span style={{color: "red"}}>*MongoDBの設定画面の変更点について（第3章／2024年3月）</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/mongodb-setup-march-2024">解説ページへ移動する</a></li>
            <li><span style={{color: "red"}}>*Cloudinaryの設定画面の変更点について（第10章）</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/cloudinary-part">解説ページへ移動する</a></li>
            <li><span style={{color: "red"}}>*Next.js version 13使用時のインストールとリンクタグについて</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/next-fullstack-book-v13">解説ページへ移動する</a></li>
            <li><span style={{color: "red"}}>*Json Web Tokenのバージョンについて</span>：本書で使っている「jsonwebtoken」はバージョン8ですが、本書発売以後に新しいバージョン9がリリースされたため、本書の記述通りに進めているとエラーの発生する場合があります。現在、バージョン9に準拠した内容へと本書の書き直しを進めていますが、エラーが発生した場合はターミナルで「npm install jsonwebtoken@^8.5.1」を実行し、「jsonwebtoken」のバージョンを見本アプリのものと準拠させてください。</li>
            <hr/>
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード（前編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            {generalData.urlToCode2 && 
                        <li>ソースコードダウンロード（後編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
            }
            <li><span className="amazon-review">Amazonでレビューする（前編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
            {generalData.reviewLink2 && 
                        <li><span className="amazon-review">Amazonでレビューする（後編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink2}>{generalData.reviewLink2}</a></li>
            }
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            <p dangerouslySetInnerHTML={{ __html: x.desciption }}/>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
    </div>
)

export default NextFullstackBookLinks
